<template>
    <div class="row">
        <div class="col-12">
            <!-- Filter -->
            <div class="accordion" id="filterAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                        <button class="accordion-button bold" type="button" data-bs-toggle="collapse" data-bs-target="#filters" aria-expanded="false" aria-controls="filters">
                            {{$t('events.filters.title')}} ({{$t('events.filters.click_to_open')}})
                        </button>
                    </h2>
                    <div id="filters" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#filterAccordion">
                        <div class="accordion-body">
                            <Form @submit="(values) => loadBlacklist(pagination.page, values)" ref="form">
                                <div class="row">
                                    <div class="col-md-3">
                                        <label for="vehicleRegistrationFilter" class="form-label">{{$t('events.filters.vehicle_registration')}}</label>
                                        <Field type="text" name="registration" class="form-control" id="vehicleRegistrationFilter" placeholder="Enter Registration"/>
                                        <ErrorMessage name="registration" class="error-feedback" />
                                    </div>  
                                </div>

                                <div class="row m-t-md">
                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-primary" style="max-width: 150px;">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('events.filters.search') }}</span>
                                        </button>
                                    </div>

                                    <div class="col-12" style="width:auto;">
                                        <button type="reset" :disabled="loading" class="btn btn-secondary" style="max-width: 150px;" @click="(values) => clearFilter(pagination.page, values)">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>{{ $t('global.clear_filter') }}</span>
                                        </button>
                                    </div>

                                    <div class="col-12" style="width:auto;">
                                        <button type="submit" :disabled="loading" class="btn btn-success" style="max-width: 150px;" @click="showAddVehicleForm">
                                            <span v-show="loading" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                            <span>Add Vehicle</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
            
            <!-- Table Heading -->
            <h1 class="table-heading m-b-xs"> {{ $t('global.blacklist') }} </h1>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/>

            <!-- Loading -->
            <div v-show="loading" style="width: 100%; text-align: center;">
                <div class="spinner-border" style="height: 4rem; width: 4rem;" role="status">
                    <span class="visually-hidden">{{ $t('events.loading') }}...</span>
                </div>
            </div>

            <!-- Blacklist -->
            <table v-show="!loading" class="table table-borderless">
                <thead>
                    <tr>
                        <th scope="col"> {{ $t('events.filters.vehicle_registration').toUpperCase() }} </th>
                        <th scope="col"> NOTES </th>
                        <th scope="col"> SEVERITY</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody style="vertical-align: middle;">
                    <tr v-show="blacklist.length < 1">
                        <td colspan="3" class="no-data-container"> {{ $t('global.no_data') }} </td>
                    </tr>
                    <!-- Table Content -->
                    <tr v-for="elem in blacklist" :key="'blacklist-' + elem.vehicle_id">
                        <td>
                           <span class="vrm bold number-plate-gbr"> {{elem.plate}} </span> 
                        </td>
                        <td> {{ elem.notes }} </td>
                        <td> {{ elem.severity }} </td>
                        <td> 
                            <button @click="deleteVehicle(elem)" class="btn btn-danger">
                                Remove
                            </button> 
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Pagination -->
            <Pagination v-show="!loading" :config="pagination" @search="(page) => loadBlacklist(page, currentParams)"/>


             <!-- Add Vehicle Modal -->
             <div class="modal fade" id="addVehicleModal" tabindex="-1" aria-labelledby="successModalTitle" style="display: none;" aria-hidden="true">
                <div class="modal-dialog modal-xl modal-dialog-centered">
                    <div class="modal-content p-h-xxl p-v-xxl">
                        <div class="modal-title flex-center">
                            <h3 class="m-r-sm"> ADD VEHICLE TO NBCS BLACKLIST </h3>
                        </div>
                        
                        <Form ref="blacklistForm" @submit="(values) => addVehicle(values)" :validation-schema="schema"> 
                            <div class="row flex-col">
                                <div class="row">
                                    <div class="col-md-6 m-b-lg">
                                        <label for="vehicleRegistration" class="form-label">{{$t('events.filters.vehicle_registration')}} <span class="text-danger">*</span> </label>
                                        <div class="col-sm-12">
                                            <Field type="text" name="registration" class="form-control" id="vehicleRegistration" placeholder="Enter Registration"/>
                                            <ErrorMessage name="registration" class="error-feedback" v-model="errorMsg"/>
                                        </div>
                                    </div>
                                    <div class="col-md-6 m-b-lg">
                                        <label for="reason" class="form-label"> Severity <span class="text-danger">*</span> </label>
                                        <div class="col-sm-12">
                                            <Field type="text" name="severity" class="form-select" id="severity" as="select" required> 
                                                <option :value="1">Low</option>
                                                <option :value="2">Medium</option>
                                                <option :value="3">High</option>
                                                <option :value="4">Extremely Dangerous</option>
                                            </Field>
                                            <ErrorMessage name="severity" class="error-feedback"/>
                                        </div>
                                    </div> 
                                </div>
                                <div class="row">
                                   <div class="m-b-lg col-md-12">
                                        <label for="vehicleMake" class="col-form-label">Notes <span class="text-danger">*</span></label>
                                        <div class="col-sm-12">
                                            <Field type="textarea" name="notes" class="form-control" id="notes" placeholder="These notes will show when we detect the vehicle on any of our ANPR sites."/>
                                            <ErrorMessage name="notes" class="error-feedback" />
                                        </div>
                                    </div>

                                </div>
                                <!-- <div class="col-md-3 col-xs-6 col-xxs-12 m-b-lg">
                                    <label for="reason" class="form-label"> {{ $t('global.reason') }} <span class="text-danger">*</span> </label>
                                    <Field type="text" name="reason" class="form-select" id="reason" as="select"> 
                                        <option value="" selected> {{$t('blacklist.select_reason')}} </option>
                                        <option v-for="reason in reasons" :value="reason" :key="reason"> {{reason}} </option>
                                    </Field>
                                    <ErrorMessage name="reason" class="error-feedback"/>
                                </div> -->
                                <div class="">
                                    <button class="btn bg-danger text-white" type="submit"> 
                                        <i class="material-icons" v-show="!adding"> add </i> 
                                        <span v-show="adding" class="spinner-border spinner-border-sm m-r-xxs"></span>
                                        {{$t('blacklist.add_to_blacklist')}}
                                    </button>
                                </div>
                            </div>  
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
//Components 
import {Form, Field, ErrorMessage } from 'vee-validate';
import Pagination from '../../components/Pagination.vue';

// Dependencies
import * as yup from 'yup';
import axios from 'axios';
import authHeader from '../../services/auth-header';


export default {
    name: 'Blacklist',
    components: { Form, Field, ErrorMessage, Pagination },
    data() {
        const schema = yup.object().shape({
            registration: yup.string().min(3, 'Must be at least 3 characters!').max(12, 'Must be no longer than 12 characters!'),
            notes: yup.string().required('Notes is required!'),
            severity: yup.number().required('Severity is required!')
        });
        return {
            schema,
            blacklist: [],
            pagination: {
                page: 0,
                showing: 0,
                total: 0,
                lastPage: 0,
                maxPage: 0
            },
            loading: true,
            currentParams: {},
            addVehicleModal: null,
            adding: false
        }
    },
    mounted() {
        this.loadBlacklist();
        this.addVehicleModal = new bootstrap.Modal(document.getElementById('addVehicleModal'));
    },
    methods: {
        loadBlacklist(page = 1, params = {}){

            axios.get(`https://server.varsanpr.com/api/nbcs?page=${page}&registration=${params.registration || ""}`, {
                headers: authHeader(),
            })
            .then(response => {
                this.blacklist = response.data.vehicles;
                this.blacklist.forEach((elem) => {
                    switch(elem.severity){
                        case 1:
                            elem.severity = "Low";
                            break;
                        case 2:
                            elem.severity = "Medium";
                            break;
                        case 3:
                            elem.severity = "High";
                            break;
                        case 4:
                            elem.severity = "Extremely Dangerous";
                            break;
                        default:
                            elem.severity = "Low";
                    }
                });
                this.pagination = response.data.pagination;
            })
            .catch(error => {
                this.$error("Failed to load blacklist", error);
            })
            .finally(() =>{
                this.loading = false;
            });

        },
        clearFilter() {
            this.$refs.form.resetForm();
            // this.loadBlacklist();
        },
        showAddVehicleForm(){
            this.addVehicleModal.show();
        },
        addVehicle(values = {}){

            this.adding = true;

            axios.put(`https://server.varsanpr.com/api/nbcs`, {
                plate: values.registration,
                notes: values.notes,
                severity: values.severity
            }, {
                headers: authHeader(),
            })
            .then(response => {
                this.$success("Vehicle added to blacklist");
                this.addVehicleModal.hide();
                this.loadBlacklist();
            })
            .catch(error => {
                this.$error("Failed to add vehicle to blacklist", error);
            })
            .finally(() =>{
                this.adding = false;
            });

        },
        deleteVehicle(vehicle){
            
            axios.delete(`https://server.varsanpr.com/api/nbcs/${vehicle.id}`, {
                headers: authHeader(),
            })
            .then(response => {
                this.$success("Vehicle removed from blacklist");
                this.loadBlacklist();
            })
            .catch(error => {
                this.$error("Failed to remove vehicle from blacklist", error);
            });

        }
    }

}
</script>

<style scoped>

.modal-title h3 {
    font-size: 30px;
}

.modal-title i {
    font-size: 37px;
    margin-bottom: 8px;
}

.modal-content p {
    font-weight: 100;
    font-size: 18px;
}

</style>